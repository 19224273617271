import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { Translate, TextFormat } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./main-positions.reducer";

export const MainPositionsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const mainPositionsEntity = useAppSelector(
    (state) => state.mainPositions.entity
  );
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="mainPositionsDetailsHeading">
          <Translate contentKey="CorpvineApp.mainPositions.detail.title">
            MainPositions
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{mainPositionsEntity.id}</dd>
          <dt>
            <span id="positionname">
              <Translate contentKey="CorpvineApp.mainPositions.positionname">
                Positionname
              </Translate>
            </span>
          </dt>
          <dd>{mainPositionsEntity.positionname}</dd>
          <dt>
            <span id="jobtitleid">
              <Translate contentKey="CorpvineApp.mainPositions.jobtitleid">
                Jobtitleid
              </Translate>
            </span>
          </dt>
          <dd>{mainPositionsEntity.jobtitleid}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="CorpvineApp.mainPositions.description">
                Description
              </Translate>
            </span>
          </dt>
          <dd>{mainPositionsEntity.description}</dd>
          <dt>
            <span id="createdby">
              <Translate contentKey="CorpvineApp.mainPositions.createdby">
                Createdby
              </Translate>
            </span>
          </dt>
          <dd>{mainPositionsEntity.createdby}</dd>
          <dt>
            <span id="modifiedby">
              <Translate contentKey="CorpvineApp.mainPositions.modifiedby">
                Modifiedby
              </Translate>
            </span>
          </dt>
          <dd>{mainPositionsEntity.modifiedby}</dd>
          <dt>
            <span id="createddate">
              <Translate contentKey="CorpvineApp.mainPositions.createddate">
                Createddate
              </Translate>
            </span>
          </dt>
          <dd>
            {mainPositionsEntity.createddate ? (
              <TextFormat
                value={mainPositionsEntity.createddate}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="modifieddate">
              <Translate contentKey="CorpvineApp.mainPositions.modifieddate">
                Modifieddate
              </Translate>
            </span>
          </dt>
          <dd>
            {mainPositionsEntity.modifieddate ? (
              <TextFormat
                value={mainPositionsEntity.modifieddate}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="isactive">
              <Translate contentKey="CorpvineApp.mainPositions.isactive">
                Isactive
              </Translate>
            </span>
          </dt>
          <dd>{mainPositionsEntity.isactive}</dd>
        </dl>
        <Button
          tag={Link}
          to="/main-positions"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        {/* &nbsp;
        <Button
          tag={Link}
          to={`/main-positions/${mainPositionsEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button> */}
      </Col>
    </Row>
  );
};

export default MainPositionsDetail;
