import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import ProjectMemberSysvineRoles from "./project-member-sysvine-roles";
import ProjectMemberSysvineRolesDetail from "./project-member-sysvine-roles-detail";
import ProjectMemberSysvineRolesUpdate from "./project-member-sysvine-roles-update";
import ProjectMemberSysvineRolesDeleteDialog from "./project-member-sysvine-roles-delete-dialog";

const ProjectMemberSysvineRolesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProjectMemberSysvineRoles />} />
    <Route path="new" element={<ProjectMemberSysvineRolesUpdate />} />
    <Route path=":id">
      <Route index element={<ProjectMemberSysvineRolesDetail />} />
      <Route path="edit" element={<ProjectMemberSysvineRolesUpdate />} />
      <Route
        path="delete"
        element={<ProjectMemberSysvineRolesDeleteDialog />}
      />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProjectMemberSysvineRolesRoutes;
