import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { Translate, TextFormat } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./career-growth-goal.reducer";

export const CareerGrowthGoalDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const careerGrowthGoalEntity = useAppSelector(
    (state) => state.careerGrowthGoal.entity
  );
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="careerGrowthGoalDetailsHeading">
          <Translate contentKey="CorpvineApp.careerGrowthGoal.detail.title">
            CareerGrowthGoal
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{careerGrowthGoalEntity.id}</dd>
          <dt>
            <Translate contentKey="CorpvineApp.careerGrowthGoal.title">
              Title
            </Translate>
          </dt>
          <dd>
            {careerGrowthGoalEntity.title
              ? careerGrowthGoalEntity.title.jobtitlename
              : ""}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.careerGrowthGoal.position">
              Position
            </Translate>
          </dt>
          <dd>
            {careerGrowthGoalEntity.position
              ? careerGrowthGoalEntity.position.positionname
              : ""}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.careerGrowthGoal.skill">
              Skill
            </Translate>
          </dt>
          <dd>
            {careerGrowthGoalEntity.skill
              ? careerGrowthGoalEntity.skill.name
              : ""}
          </dd>
          <dt>
            <span id="expected_grade">
              <Translate contentKey="CorpvineApp.careerGrowthGoal.expected_grade">
                Expected Grade
              </Translate>
            </span>
          </dt>
          <dd>{careerGrowthGoalEntity.expected_grade}</dd>
          <dt>
            <Translate contentKey="CorpvineApp.careerGrowthGoal.created_by">
              Created By
            </Translate>
          </dt>
          <dd>
            {careerGrowthGoalEntity.created_by
              ? careerGrowthGoalEntity.created_by.login
              : ""}
          </dd>
          <dt>
            <span id="created_at">
              <Translate contentKey="CorpvineApp.careerGrowthGoal.created_at">
                Created At
              </Translate>
            </span>
          </dt>
          <dd>
            {careerGrowthGoalEntity.created_at ? (
              <TextFormat
                value={careerGrowthGoalEntity.created_at}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.careerGrowthGoal.modified_by">
              Modified By
            </Translate>
          </dt>
          <dd>
            {careerGrowthGoalEntity.modified_by
              ? careerGrowthGoalEntity.modified_by.login
              : ""}
          </dd>
          <dt>
            <span id="modified_at">
              <Translate contentKey="CorpvineApp.careerGrowthGoal.modified_at">
                Modified At
              </Translate>
            </span>
          </dt>
          <dd>
            {careerGrowthGoalEntity.modified_at ? (
              <TextFormat
                value={careerGrowthGoalEntity.modified_at}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
        </dl>
        <Button
          tag={Link}
          to="/career-growth-goal"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/career-growth-goal/${careerGrowthGoalEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CareerGrowthGoalDetail;
