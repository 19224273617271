import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { Translate, TextFormat } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./main-job-titles.reducer";

export const MainJobTitlesDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const mainJobTitlesEntity = useAppSelector(
    (state) => state.mainJobTitles.entity
  );
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="mainJobTitlesDetailsHeading">
          <Translate contentKey="CorpvineApp.mainJobTitles.detail.title">
            MainJobTitles
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{mainJobTitlesEntity.id}</dd>
          <dt>
            <span id="jobtitlecode">
              <Translate contentKey="CorpvineApp.mainJobTitles.jobtitlecode">
                Jobtitlecode
              </Translate>
            </span>
          </dt>
          <dd>{mainJobTitlesEntity.jobtitlecode}</dd>
          <dt>
            <span id="jobtitlename">
              <Translate contentKey="CorpvineApp.mainJobTitles.jobtitlename">
                Jobtitlename
              </Translate>
            </span>
          </dt>
          <dd>{mainJobTitlesEntity.jobtitlename}</dd>
          <dt>
            <span id="jobdescription">
              <Translate contentKey="CorpvineApp.mainJobTitles.jobdescription">
                Jobdescription
              </Translate>
            </span>
          </dt>
          <dd>{mainJobTitlesEntity.jobdescription}</dd>
          <dt>
            <span id="minexperiencerequired">
              <Translate contentKey="CorpvineApp.mainJobTitles.minexperiencerequired">
                Minexperiencerequired
              </Translate>
            </span>
          </dt>
          <dd>{mainJobTitlesEntity.minexperiencerequired}</dd>
          <dt>
            <span id="jobpaygradecode">
              <Translate contentKey="CorpvineApp.mainJobTitles.jobpaygradecode">
                Jobpaygradecode
              </Translate>
            </span>
          </dt>
          <dd>{mainJobTitlesEntity.jobpaygradecode}</dd>
          <dt>
            <span id="jobpayfrequency">
              <Translate contentKey="CorpvineApp.mainJobTitles.jobpayfrequency">
                Jobpayfrequency
              </Translate>
            </span>
          </dt>
          <dd>{mainJobTitlesEntity.jobpayfrequency}</dd>
          <dt>
            <span id="comments">
              <Translate contentKey="CorpvineApp.mainJobTitles.comments">
                Comments
              </Translate>
            </span>
          </dt>
          <dd>{mainJobTitlesEntity.comments}</dd>
          <dt>
            <span id="createdby">
              <Translate contentKey="CorpvineApp.mainJobTitles.createdby">
                Createdby
              </Translate>
            </span>
          </dt>
          <dd>{mainJobTitlesEntity.createdby}</dd>
          <dt>
            <span id="modifiedby">
              <Translate contentKey="CorpvineApp.mainJobTitles.modifiedby">
                Modifiedby
              </Translate>
            </span>
          </dt>
          <dd>{mainJobTitlesEntity.modifiedby}</dd>
          <dt>
            <span id="createddate">
              <Translate contentKey="CorpvineApp.mainJobTitles.createddate">
                Createddate
              </Translate>
            </span>
          </dt>
          <dd>
            {mainJobTitlesEntity.createddate ? (
              <TextFormat
                value={mainJobTitlesEntity.createddate}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="modifieddate">
              <Translate contentKey="CorpvineApp.mainJobTitles.modifieddate">
                Modifieddate
              </Translate>
            </span>
          </dt>
          <dd>
            {mainJobTitlesEntity.modifieddate ? (
              <TextFormat
                value={mainJobTitlesEntity.modifieddate}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="isactive">
              <Translate contentKey="CorpvineApp.mainJobTitles.isactive">
                Isactive
              </Translate>
            </span>
          </dt>
          <dd>{mainJobTitlesEntity.isactive}</dd>
        </dl>
        <Button
          tag={Link}
          to="/main-job-titles"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        {/* &nbsp;
        <Button
          tag={Link}
          to={`/main-job-titles/${mainJobTitlesEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button> */}
      </Col>
    </Row>
  );
};

export default MainJobTitlesDetail;
