import axios from "axios";
import {
  createAsyncThunk,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";

import { cleanEntity } from "app/shared/util/entity-utils";
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
} from "app/shared/reducers/reducer.utils";
import {
  IMainJobTitles,
  defaultValue,
} from "app/shared/model/main-job-titles.model";

const initialState: EntityState<IMainJobTitles> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = "api/main-job-titles";

// Actions

export const getEntities = createAsyncThunk(
  "mainJobTitles/fetch_entity_list",
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}${
      sort ? `?page=${page}&size=${size}&sort=${sort}&` : "?"
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<IMainJobTitles[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  "mainJobTitles/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IMainJobTitles>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const MainJobTitlesSlice = createEntitySlice({
  name: "mainJobTitles",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers["x-total-count"], 10),
        };
      })
      .addMatcher(isPending(getEntities, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = MainJobTitlesSlice.actions;

// Reducer
export default MainJobTitlesSlice.reducer;
