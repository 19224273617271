import projectMemberStatuses from "app/entities/project-member-statuses/project-member-statuses.reducer";
import skillCategory from "app/entities/skill-category/skill-category.reducer";
import skill from "app/entities/skill/skill.reducer";
import careerGrowthGoal from "app/entities/career-growth-goal/career-growth-goal.reducer";
import skillCategorySkillLink from "app/entities/skill-category-skill-link/skill-category-skill-link.reducer";
import titlePositionLink from "app/entities/title-position-link/title-position-link.reducer";
import mainJobTitles from "app/entities/main-job-titles/main-job-titles.reducer";
import mainPositions from "app/entities/main-positions/main-positions.reducer";
import prvnKpi from "app/entities/prvn-kpi/prvn-kpi.reducer";
import suUser from "app/entities/su-user/su-user.reducer";
import projectMemberSysvineRoles from "app/entities/project-member-sysvine-roles/project-member-sysvine-roles.reducer";
import projectMemberClientRoles from "app/entities/project-member-client-roles/project-member-client-roles.reducer";
import projectMemberCostTypes from "app/entities/project-member-cost-types/project-member-cost-types.reducer";
import location from "app/entities/location/location.reducer";
import category from "app/entities/category/category.reducer";
import source from "app/entities/source/source.reducer";
import review from "app/entities/review/review.reducer";
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  prvnKpi,
  skillCategory,
  skill,
  careerGrowthGoal,
  skillCategorySkillLink,
  titlePositionLink,
  mainJobTitles,
  mainPositions,
  suUser,
  projectMemberSysvineRoles,
  projectMemberClientRoles,
  projectMemberCostTypes,
  projectMemberStatuses,
  location,
  category,
  source,
  review,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
