export enum grade {
  A = 'A',

  B = 'B',

  C = 'C',

  D = 'D',

  E = 'E',

  F = 'F',
}
