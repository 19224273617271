import dayjs from "dayjs";
import { ISkill } from "app/shared/model/skill.model";
import { IMainJobTitles } from "app/shared/model/main-job-titles.model";
import { IMainPositions } from "app/shared/model/main-positions.model";
import { ISuUser } from "app/shared/model/su-user.model";
import { grade } from "app/shared/model/enumerations/grade.model";

export interface ICareerGrowthGoal {
  id?: number;
  expected_grade?: grade | null;
  created_at?: string;
  modified_at?: string;
  skill?: ISkill;
  title?: IMainJobTitles;
  position?: IMainPositions;
  created_by?: ISuUser;
  modified_by?: ISuUser;
}

export const defaultValue: Readonly<ICareerGrowthGoal> = {};
