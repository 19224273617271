import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import MainPositions from "./main-positions";
import MainPositionsDetail from "./main-positions-detail";

const MainPositionsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MainPositions />} />
    <Route path=":id">
      <Route index element={<MainPositionsDetail />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MainPositionsRoutes;
