import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Row, Col, FormText } from "reactstrap";
import { DROP_DOWN_LIMIT } from "app/shared/util/pagination.constants";
import {
  isNumber,
  Translate,
  translate,
  ValidatedField,
  ValidatedForm,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { mapIdList } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { ISuUser } from "app/shared/model/su-user.model";
import { getEntities as getSuUsers } from "app/entities/su-user/su-user.reducer";
import { IPrvnKpi } from "app/shared/model/prvn-kpi.model";
import { kpitype } from "app/shared/model/enumerations/kpitype.model";
import { auditperiod } from "app/shared/model/enumerations/auditperiod.model";
import {
  getEntity,
  updateEntity,
  createEntity,
  reset,
} from "./prvn-kpi.reducer";

export const PrvnKpiUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const suUsers = useAppSelector((state) => state.suUser.entities);
  const prvnKpiEntity = useAppSelector((state) => state.prvnKpi.entity);
  const loading = useAppSelector((state) => state.prvnKpi.loading);
  const updating = useAppSelector((state) => state.prvnKpi.updating);
  const updateSuccess = useAppSelector((state) => state.prvnKpi.updateSuccess);
  const kpitypeValues = Object.keys(kpitype);
  const auditperiodValues = Object.keys(auditperiod);

  const handleClose = () => {
    navigate("/prvn-kpi" + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getSuUsers({size:DROP_DOWN_LIMIT,
      sort: 'login,asc'}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    values.created_date = convertDateTimeToServer(values.created_date);
    values.modified_date = convertDateTimeToServer(values.modified_date);

    const entity = {
      ...prvnKpiEntity,
      ...values,
      created_by: suUsers.find(
        (it) => it.id.toString() === values.created_by.toString()
      ),
      modified_by: isNew?(suUsers.find(
        (it) => it.id.toString() === values.created_by.toString()
      ))
      :(suUsers.find(
        (it) => it.id.toString() === values.modified_by.toString()
      )),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          created_date: displayDefaultDateTime(),
          modified_date: displayDefaultDateTime(),
        }
      : {
          kpi_type: "PRVN",
          audit_period: "quarterly",
          ...prvnKpiEntity,
          created_date: convertDateTimeFromServer(prvnKpiEntity.created_date),
          modified_date: displayDefaultDateTime(),
          created_by: prvnKpiEntity?.created_by?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.prvnKpi.home.createOrEditLabel"
            data-cy="PrvnKpiCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.prvnKpi.home.createOrEditLabel">
              Create or edit a PrvnKpi
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="prvn-kpi-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate("CorpvineApp.prvnKpi.name")}
                id="prvn-kpi-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate("entity.validation.required"),
                  },
                }}
              />
              <ValidatedField
                label={translate("CorpvineApp.prvnKpi.description")}
                id="prvn-kpi-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.prvnKpi.isactive")}
                id="prvn-kpi-isactive"
                name="isactive"
                placeholder="1-Active or 0-Inactive"
                data-cy="isactive"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate("entity.validation.required"),
                  },
                  min: {
                    value: 0,
                    message: translate("entity.validation.min", { min: 0 }),
                  },
                  max: {
                    value: 1,
                    message: translate("entity.validation.max", { max: 1 }),
                  },
                  validate: (v) =>
                    isNumber(v) || translate("entity.validation.number"),
                }}
              />
              <ValidatedField
                label={translate("CorpvineApp.prvnKpi.kpi_type")}
                id="prvn-kpi-kpi_type"
                name="kpi_type"
                data-cy="kpi_type"
                type="select"
              >
                {kpitypeValues.map((kpitype) => (
                  <option value={kpitype} key={kpitype}>
                    {translate("CorpvineApp.kpitype." + kpitype)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate("CorpvineApp.prvnKpi.level")}
                id="prvn-kpi-level"
                name="level"
                data-cy="level"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate("entity.validation.required"),
                  },
                  validate: (v) =>
                    isNumber(v) || translate("entity.validation.number"),
                }}
              />
              <ValidatedField
                label={translate("CorpvineApp.prvnKpi.kpi_order")}
                id="prvn-kpi-kpi_order"
                name="kpi_order"
                data-cy="kpi_order"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.prvnKpi.audit_period")}
                id="prvn-kpi-audit_period"
                name="audit_period"
                data-cy="audit_period"
                type="select"
              >
                {auditperiodValues.map((auditperiod) => (
                  <option value={auditperiod} key={auditperiod}>
                    {translate("CorpvineApp.auditperiod." + auditperiod)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate("CorpvineApp.prvnKpi.parent_id")}
                id="prvn-kpi-parent_id"
                name="parent_id"
                data-cy="parent_id"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.prvnKpi.max_score")}
                id="prvn-kpi-max_score"
                name="max_score"
                data-cy="max_score"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate("entity.validation.required"),
                  },
                  validate: (v) =>
                    isNumber(v) || translate("entity.validation.number"),
                }}
              />
              {isNew
              ? (
              <ValidatedField
                id="prvn-kpi-created_by"
                name="created_by"
                data-cy="created_by"
                label={translate("CorpvineApp.prvnKpi.created_by")}
                type="select"
                required
              >
                <option value="" key="0" />
                {suUsers
                  ? suUsers.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>)
              :null
              }
              {!isNew
              ? (<ValidatedField
                id="prvn-kpi-modified_by"
                name="modified_by"
                data-cy="modified_by"
                label={translate("CorpvineApp.prvnKpi.modified_by")}
                type="select"
                required
              >
                <option value="" key="0" />
                {suUsers
                  ? suUsers.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>)
              :null
              }
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/prvn-kpi"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PrvnKpiUpdate;
