import React, { useEffect, useState } from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

const accountMenuItemsAuthenticated = () => (
  <>
    {/* <MenuItem icon="wrench" to="/account/settings" data-cy="settings">
      <Translate contentKey="global.menu.account.settings">Settings</Translate>
    </MenuItem>
    <MenuItem icon="lock" to="/account/password" data-cy="passwordItem">
      <Translate contentKey="global.menu.account.password">Password</Translate>
    </MenuItem> */}
    {/* <MenuItem icon="cloud" to="/account/sessions">
      <Translate contentKey="global.menu.account.sessions">Sessions</Translate>
    </MenuItem> */}
    <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuItem>
  </>
);

const accountMenuItems = () => (
  <>
    <MenuItem id="login-item" icon="sign-in-alt" to="/login" data-cy="login">
      <Translate contentKey="global.menu.account.login">Sign in</Translate>
    </MenuItem>
    {/* <MenuItem icon="user-plus" to="/account/register" data-cy="register">
      <Translate contentKey="global.menu.account.register">Register</Translate>
    </MenuItem> */}
  </>
);

export const AccountMenu = ({ isAuthenticated = false }) => {
  const [authenticate, setAuthenticate] = useState(false);

  useEffect(() => {
    setAuthenticate(isAuthenticated);
  }, [isAuthenticated]);

  return (
    <NavDropdown icon="user" name={translate('global.menu.account.main')} id="account-menu" data-cy="accountMenu">
    {authenticate ? 
      ( <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
          Sign out
        </MenuItem>
    ) :
    (
      <MenuItem id="login-item" icon="sign-in-alt" to="/login" data-cy="login">
        Sign in
      </MenuItem>
    )
  }
  </NavDropdown>
  )
}

export default AccountMenu;
