import './home.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Row, Col, Alert } from 'reactstrap';

import { useAppSelector } from 'app/config/store';

export const Home = () => {
  const account = useAppSelector(state => state.authentication.account);

  return (
    <Row className='home'>
      <Col md="12" className='home-content'>
        <h2>
          <Translate contentKey="home.title">Welcome to CorpVine!</Translate>
        </h2>
      </Col>
    </Row>
  );
};

export default Home;
