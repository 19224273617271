import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SkillCategorySkillLink from './skill-category-skill-link';
import SkillCategorySkillLinkDetail from './skill-category-skill-link-detail';
import SkillCategorySkillLinkUpdate from './skill-category-skill-link-update';
import SkillCategorySkillLinkDeleteDialog from './skill-category-skill-link-delete-dialog';

const SkillCategorySkillLinkRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SkillCategorySkillLink />} />
    <Route path="new" element={<SkillCategorySkillLinkUpdate />} />
    <Route path=":id">
      <Route index element={<SkillCategorySkillLinkDetail />} />
      <Route path="edit" element={<SkillCategorySkillLinkUpdate />} />
      <Route path="delete" element={<SkillCategorySkillLinkDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SkillCategorySkillLinkRoutes;
