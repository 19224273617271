import dayjs from "dayjs";

export interface IMainPositions {
  id?: number;
  positionname?: string | null;
  jobtitleid?: number;
  description?: string | null;
  createdby?: number | null;
  modifiedby?: number | null;
  createddate?: string;
  modifieddate?: string;
  isactive?: number;
}

export const defaultValue: Readonly<IMainPositions> = {};
