import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import CareerGrowthGoal from "./career-growth-goal";
import CareerGrowthGoalDetail from "./career-growth-goal-detail";
import CareerGrowthGoalUpdate from "./career-growth-goal-update";
import CareerGrowthGoalDeleteDialog from "./career-growth-goal-delete-dialog";

const CareerGrowthGoalRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CareerGrowthGoal />} />
    <Route path="new" element={<CareerGrowthGoalUpdate />} />
    <Route path=":id">
      <Route index element={<CareerGrowthGoalDetail />} />
      <Route path="edit" element={<CareerGrowthGoalUpdate />} />
      <Route path="delete" element={<CareerGrowthGoalDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CareerGrowthGoalRoutes;
