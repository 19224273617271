import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import PrvnKpi from "./prvn-kpi";
import PrvnKpiDetail from "./prvn-kpi-detail";
import PrvnKpiUpdate from "./prvn-kpi-update";
import PrvnKpiDeleteDialog from "./prvn-kpi-delete-dialog";

const PrvnKpiRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PrvnKpi />} />
    <Route path="new" element={<PrvnKpiUpdate />} />
    <Route path=":id">
      <Route index element={<PrvnKpiDetail />} />
      <Route path="edit" element={<PrvnKpiUpdate />} />
      <Route path="delete" element={<PrvnKpiDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PrvnKpiRoutes;
