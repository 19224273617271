import dayjs from "dayjs";

export interface IMainJobTitles {
  id?: number;
  jobtitlecode?: string;
  jobtitlename?: string;
  jobdescription?: string | null;
  minexperiencerequired?: number | null;
  jobpaygradecode?: string | null;
  jobpayfrequency?: string | null;
  comments?: string | null;
  createdby?: number | null;
  modifiedby?: number | null;
  createddate?: string;
  modifieddate?: string;
  isactive?: number;
}

export const defaultValue: Readonly<IMainJobTitles> = {};
