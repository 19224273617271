import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import {
  Translate,
  TextFormat,
  getSortState,
  JhiPagination,
  JhiItemCount,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from "app/config/constants";
import {
  ASC,
  DESC,
  ITEMS_PER_PAGE,
  SORT,
} from "app/shared/util/pagination.constants";
import { overridePaginationStateWithQueryParams } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { IMainPositions } from "app/shared/model/main-positions.model";
import { getEntities } from "./main-positions.reducer";

export const MainPositions = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      getSortState(location, ITEMS_PER_PAGE, "id"),
      location.search
    )
  );

  const mainPositionsList = useAppSelector(
    (state) => state.mainPositions.entities
  );
  const loading = useAppSelector((state) => state.mainPositions.loading);
  const totalItems = useAppSelector((state) => state.mainPositions.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(",");
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = (p) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = (currentPage) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="main-positions-heading" data-cy="MainPositionsHeading">
        <Translate contentKey="CorpvineApp.mainPositions.home.title">
          Main Positions
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{" "}
            <Translate contentKey="CorpvineApp.mainPositions.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
        </div>
      </h2>
      <div className="table-responsive">
        {mainPositionsList && mainPositionsList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort("id")}>
                  <Translate contentKey="CorpvineApp.mainPositions.id">
                    ID
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("positionname")}>
                  <Translate contentKey="CorpvineApp.mainPositions.positionname">
                    Positionname
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("jobtitleid")}>
                  <Translate contentKey="CorpvineApp.mainPositions.jobtitleid">
                    Jobtitleid
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("description")}>
                  <Translate contentKey="CorpvineApp.mainPositions.description">
                    Description
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("createdby")}>
                  <Translate contentKey="CorpvineApp.mainPositions.createdby">
                    Createdby
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("modifiedby")}>
                  <Translate contentKey="CorpvineApp.mainPositions.modifiedby">
                    Modifiedby
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("createddate")}>
                  <Translate contentKey="CorpvineApp.mainPositions.createddate">
                    Createddate
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("modifieddate")}>
                  <Translate contentKey="CorpvineApp.mainPositions.modifieddate">
                    Modifieddate
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort("isactive")}>
                  <Translate contentKey="CorpvineApp.mainPositions.isactive">
                    Isactive
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {mainPositionsList.map((mainPositions, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/main-positions/${mainPositions.id}`}
                      color="link"
                      size="sm"
                    >
                      {mainPositions.id}
                    </Button>
                  </td>
                  <td>{mainPositions.positionname}</td>
                  <td>{mainPositions.jobtitleid}</td>
                  <td>{mainPositions.description}</td>
                  <td>{mainPositions.createdby}</td>
                  <td>{mainPositions.modifiedby}</td>
                  <td>
                    {mainPositions.createddate ? (
                      <TextFormat
                        type="date"
                        value={mainPositions.createddate}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td>
                    {mainPositions.modifieddate ? (
                      <TextFormat
                        type="date"
                        value={mainPositions.modifieddate}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td>{mainPositions.isactive}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/main-positions/${mainPositions.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="CorpvineApp.mainPositions.home.notFound">
                No Main Positions found
              </Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div
          className={
            mainPositionsList && mainPositionsList.length > 0 ? "" : "d-none"
          }
        >
          <div className="justify-content-center d-flex">
            <JhiItemCount
              page={paginationState.activePage}
              total={totalItems}
              itemsPerPage={paginationState.itemsPerPage}
              i18nEnabled
            />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MainPositions;
