import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import TitlePositionLink from "./title-position-link";
import TitlePositionLinkDetail from "./title-position-link-detail";

const TitlePositionLinkRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TitlePositionLink />} />
    <Route path=":id">
      <Route index element={<TitlePositionLinkDetail />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TitlePositionLinkRoutes;
