import { ISkill } from 'app/shared/model/skill.model';
import { ISkillCategory } from 'app/shared/model/skill-category.model';

export interface ISkillCategorySkillLink {
  id?: number;
  skill?: ISkill;
  skillCategory?: ISkillCategory;
}

export const defaultValue: Readonly<ISkillCategorySkillLink> = {};
