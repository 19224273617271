import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Row, Col, FormText } from "reactstrap";
import {
  isNumber,
  Translate,
  translate,
  ValidatedField,
  ValidatedForm,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DROP_DOWN_LIMIT } from "app/shared/util/pagination.constants";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { mapIdList } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { ISkill } from "app/shared/model/skill.model";
import { getEntities as getSkills } from "app/entities/skill/skill.reducer";
import { IMainJobTitles } from "app/shared/model/main-job-titles.model";
import { getEntities as getMainJobTitles } from "app/entities/main-job-titles/main-job-titles.reducer";
import { IMainPositions } from "app/shared/model/main-positions.model";
import { getEntities as getMainPositions } from "app/entities/main-positions/main-positions.reducer";
import { ISuUser } from "app/shared/model/su-user.model";
import { getEntities as getSuUsers } from "app/entities/su-user/su-user.reducer";
import { ICareerGrowthGoal } from "app/shared/model/career-growth-goal.model";
import { grade } from "app/shared/model/enumerations/grade.model";
import {
  getEntity,
  updateEntity,
  createEntity,
  reset,
} from "./career-growth-goal.reducer";

export const CareerGrowthGoalUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const skills = useAppSelector((state) => state.skill.entities);
  const mainJobTitles = useAppSelector((state) => state.mainJobTitles.entities);
  const mainPositions = useAppSelector((state) => state.mainPositions.entities);
  const suUsers = useAppSelector((state) => state.suUser.entities);
  const careerGrowthGoalEntity = useAppSelector(
    (state) => state.careerGrowthGoal.entity
  );
  const loading = useAppSelector((state) => state.careerGrowthGoal.loading);
  const updating = useAppSelector((state) => state.careerGrowthGoal.updating);
  const updateSuccess = useAppSelector(
    (state) => state.careerGrowthGoal.updateSuccess
  );
  const gradeValues = Object.keys(grade);

  const handleClose = () => {
    navigate("/career-growth-goal" + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getSkills({size:DROP_DOWN_LIMIT,
      sort: 'name,asc'}));
    dispatch(getMainJobTitles({size:DROP_DOWN_LIMIT,
      sort: 'jobtitlename,asc'}));
    dispatch(getMainPositions({size:DROP_DOWN_LIMIT,
      sort: 'positionname,asc'}));
      dispatch(getSuUsers({size:DROP_DOWN_LIMIT,
        sort: 'login,asc'}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    values.created_at = convertDateTimeToServer(values.created_at);
    values.modified_at = convertDateTimeToServer(values.modified_at);

    const entity = {
      ...careerGrowthGoalEntity,
      ...values,
      skill: skills.find((it) => it.id.toString() === values.skill.toString()),
      title: mainJobTitles.find(
        (it) => it.id.toString() === values.title.toString()
      ),
      position: mainPositions.find(
        (it) => it.id.toString() === values.position.toString()
      ),
      created_by: suUsers.find(
        (it) => it.id.toString() === values.created_by.toString()
      ),
      modified_by: isNew?(suUsers.find(
        (it) => it.id.toString() === values.created_by.toString()
      ))
      :(suUsers.find(
        (it) => it.id.toString() === values.modified_by.toString()
      )),
      
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          created_at: displayDefaultDateTime(),
          modified_at: displayDefaultDateTime(),
          modified_by: careerGrowthGoalEntity?.created_by?.id,
        }
      : {
          expected_grade: "A",
          ...careerGrowthGoalEntity,
          created_at: convertDateTimeFromServer(
            careerGrowthGoalEntity.created_at
          ),
          modified_at: displayDefaultDateTime(),
          skill: careerGrowthGoalEntity?.skill?.id,
          title: careerGrowthGoalEntity?.title?.id,
          position: careerGrowthGoalEntity?.position?.id,
          created_by: careerGrowthGoalEntity?.created_by?.id,
          // modified_by: careerGrowthGoalEntity?.modified_by?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.careerGrowthGoal.home.createOrEditLabel"
            data-cy="CareerGrowthGoalCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.careerGrowthGoal.home.createOrEditLabel">
              Create or edit a CareerGrowthGoal
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="career-growth-goal-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                id="career-growth-goal-title"
                name="title"
                data-cy="title"
                label={translate("CorpvineApp.careerGrowthGoal.title")}
                type="select"
                required
              >
                <option value="" key="0" />
                {mainJobTitles
                  ? mainJobTitles.map((otherEntity) => (
                    otherEntity.isactive?
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.jobtitlename}
                      </option>
                      : null
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="career-growth-goal-position"
                name="position"
                data-cy="position"
                label={translate("CorpvineApp.careerGrowthGoal.position")}
                type="select"
                required
              >
                <option value="" key="0" />
                {mainPositions
                  ? mainPositions.map((otherEntity) => (
                    otherEntity.isactive?
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.positionname}
                      </option>
                      : null
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="career-growth-goal-skill"
                name="skill"
                data-cy="skill"
                label={translate("CorpvineApp.careerGrowthGoal.skill")}
                type="select"
                required
              >
                <option value="" key="0" />
                {skills
                  ? skills.map((otherEntity) => (
                    otherEntity.isactive?
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                      : null
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate("CorpvineApp.careerGrowthGoal.expected_grade")}
                id="career-growth-goal-expected_grade"
                name="expected_grade"
                data-cy="expected_grade"
                type="select"
              >
                {gradeValues.map((grade) => (
                  <option value={grade} key={grade}>
                    {translate("CorpvineApp.grade." + grade)}
                  </option>
                ))}
              </ValidatedField>

            {isNew
              ? (
              <ValidatedField
                id="career-growth-goal-created_by"
                name="created_by"
                data-cy="created_by"
                label={translate("CorpvineApp.careerGrowthGoal.created_by")}
                type="select"
                required
              >
                <option value="" key="0" />
                {suUsers
                  ? suUsers.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>)
              : null
            }
            {!isNew
              ? (
              <ValidatedField
                id="career-growth-goal-modified_by"
                name="modified_by"
                data-cy="modified_by"
                label={translate("CorpvineApp.careerGrowthGoal.modified_by")}
                type="select"
                required
              >
                <option value="" key="0" />
                {suUsers
                  ? suUsers.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              )
              : null
            }
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/career-growth-goal"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CareerGrowthGoalUpdate;
