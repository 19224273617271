import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import ProjectMemberCostTypes from "./project-member-cost-types";
import ProjectMemberCostTypesDetail from "./project-member-cost-types-detail";
import ProjectMemberCostTypesUpdate from "./project-member-cost-types-update";
import ProjectMemberCostTypesDeleteDialog from "./project-member-cost-types-delete-dialog";

const ProjectMemberCostTypesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProjectMemberCostTypes />} />
    <Route path="new" element={<ProjectMemberCostTypesUpdate />} />
    <Route path=":id">
      <Route index element={<ProjectMemberCostTypesDetail />} />
      <Route path="edit" element={<ProjectMemberCostTypesUpdate />} />
      <Route path="delete" element={<ProjectMemberCostTypesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProjectMemberCostTypesRoutes;
