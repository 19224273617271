import dayjs from "dayjs";
import { ISuUser } from "app/shared/model/su-user.model";

export interface ISkillCategory {
  id?: number;
  name?: string;
  description?: string | null;
  isactive?: number;
  createddate?: string;
  modifieddate?: string;
  createdby?: ISuUser;
  modifiedby?: ISuUser;
}

export const defaultValue: Readonly<ISkillCategory> = {};
