import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import MainJobTitles from "./main-job-titles";
import MainJobTitlesDetail from "./main-job-titles-detail";

const MainJobTitlesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MainJobTitles />} />
    <Route path=":id">
      <Route index element={<MainJobTitlesDetail />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MainJobTitlesRoutes;
