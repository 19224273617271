import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import ProjectMemberClientRoles from "./project-member-client-roles";
import ProjectMemberClientRolesDetail from "./project-member-client-roles-detail";
import ProjectMemberClientRolesUpdate from "./project-member-client-roles-update";
import ProjectMemberClientRolesDeleteDialog from "./project-member-client-roles-delete-dialog";

const ProjectMemberClientRolesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProjectMemberClientRoles />} />
    <Route path="new" element={<ProjectMemberClientRolesUpdate />} />
    <Route path=":id">
      <Route index element={<ProjectMemberClientRolesDetail />} />
      <Route path="edit" element={<ProjectMemberClientRolesUpdate />} />
      <Route path="delete" element={<ProjectMemberClientRolesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProjectMemberClientRolesRoutes;
