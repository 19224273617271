import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import ProjectMemberStatuses from "./project-member-statuses";
import ProjectMemberStatusesDetail from "./project-member-statuses-detail";
import ProjectMemberStatusesUpdate from "./project-member-statuses-update";
import ProjectMemberStatusesDeleteDialog from "./project-member-statuses-delete-dialog";

const ProjectMemberStatusesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProjectMemberStatuses />} />
    <Route path="new" element={<ProjectMemberStatusesUpdate />} />
    <Route path=":id">
      <Route index element={<ProjectMemberStatusesDetail />} />
      <Route path="edit" element={<ProjectMemberStatusesUpdate />} />
      <Route path="delete" element={<ProjectMemberStatusesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProjectMemberStatusesRoutes;
